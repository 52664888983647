const animateCard = () => {
    const controller = new ScrollMagic.Controller();
    $('.descr-row').each(function () {
        const tlScroll = new TimelineMax();
        const img = $(this).find('.descr__img-wrap');
        // const elHeight = $(this).outerHeight();
        if (!$(this).hasClass('reverse')) {
            tlScroll
                .to(img, 0.6, {
                    top: '-17%',
                    left: '-14%',
                    ease: Power0.easeNone,
                });
            new ScrollMagic.Scene({
                    triggerElement: this,
                    triggerHook: 0.5,
                })
                .setTween(tlScroll)
                .addTo(controller);
        } else {
            tlScroll
                .to(img, 0.5, {
                    top: '-17%',
                    left: '14%',
                    ease: Power0.easeNone,
                });
            const scene = new ScrollMagic.Scene({
                    triggerElement: this,
                    triggerHook: 0.5,
                })
                .setTween(tlScroll)
                .addTo(controller);
        }
    });
};

const reviewSlider = function initProductSlider() {
    const slider = $('.js-review-slider');
    const img = $('.js-review-img div');

    $(slider).on({
        afterChange: (event, slick, currentSlide) => {
            $('.benefit__item').removeClass('is-active');
            $('.benefit__item').eq(currentSlide).addClass('is-active');
            $(img).hide();
            $(img).eq(currentSlide).show(0);
        },
    }).slick({
        fade: true,
        infinite: true,
        slideToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 300,
        appendArrows: $('.review__arrow'),
    });

    $('.benefit__item').click((e) => {
        $(slider).slick('slickPause');
        const index = $('.benefit__item').index(e.currentTarget);
        $(slider).finish().slick('slickGoTo', index);
        $('.benefit__item').removeClass('is-active');
        $(this).addClass('is-active');
        $(img).hide();
        $(img).eq(index).show();
    });
};

const animateGreetScreen = () => {
    const controller = new ScrollMagic.Controller();
    const w = $(window).outerWidth();
    let maskX = '0%';
    let maskScale = 1.35;
    if (w < 430) {
        maskX = '0%';
        maskScale = 1.28;
    }
    const tlBubble = new TimelineMax()
        .to('.pill-bubble--orange', 6, {
            y: -6,
            repeat: -1,
            yoyo: true,
            transformOrigin: '50% 50%'
        })
        .to('.pill-bubble--orange', 6, {
            x: 5,
            repeat: -1,
            yoyo: true,
            transformOrigin: '50% 50%'
        }, '-=3')
        .to('.pill-bubble--2', 6, {
            y: '+=10',
            repeat: -1,
            yoyo: true,
        }, -6)
        .to('.pill-bubble--2', 6, {
            x: '+=6',
            repeat: -1,
            yoyo: true,
        }, -6)
        .to('.pill-bubble--3', 6, {
            y: '-=5',
            repeat: -1,
            yoyo: true,
        }, -6)
        .to('.pill-bubble--3', 6, {
            x: '+=6',
            repeat: -1,
            yoyo: true,
        }, -6)
        .to('.pill-bubble--4', 6, {
            y: '-=5',
            repeat: -1,
            yoyo: true,
        }, -6)
        .to('.pill-bubble--4', 6, {
            x: '-=6',
            repeat: -1,
            yoyo: true,
        }, -3)
        .to('.pill-bubble--5', 6, {
            y: '-=5',
            repeat: -1,
            yoyo: true,
        }, -6)
        .to('.pill-bubble--5', 6, {
            x: '+=6',
            repeat: -1,
            yoyo: true,
        }, -3)
        .to('.pill-bubble--6', 6, {
            y: '-=5',
            repeat: -1,
            yoyo: true,
        }, -6)
        .to('.pill-bubble--6', 6, {
            x: '-=6',
            repeat: -1,
            yoyo: true,
        }, -3)
    new ScrollMagic.Scene({
            triggerElement: '#trigger',
            triggerHook: 0.2,
            offset: 500,
        })
        .setTween(tlBubble)
        .addTo(controller);


    const tl = new TimelineMax()
        .to('.greet__header', 0.7, {
            autoAlpha: 0,
            y: '-30%',
        })
        .to('.greet__text', 0.7, {
            autoAlpha: 0,
            y: '10%',
        }, '-=0.7')
        .to('.greet .scroll', 0.7, {
            autoAlpha: 0,
        }, '-=0.7')
        .to('.greet__mask', 2, {
            scaleX: maskScale,
            scaleY: maskScale,
            x: maskX,
            // y: maskY,
            ease: Power0.easeOut,
        }, '-=0.5')
        .to($('.greet'), 2, {
            autoAlpha: 0.03,
        })
        .to('.hero__text .title', 0.5, {
            y: 0,
            autoAlpha: 1,
            ease: Power0.easeNone,
        }, '-=0.1')
        .to('.hero__text p', 0.5, {
            y: 0,
            autoAlpha: 1,
            ease: Power0.easeNone,
        }, '-=0.1')
        .to($('.greet'), 2, {
            autoAlpha: 0,
        })
    // .to('.doctor-1', 6, {
    //     x: '-=6',
    //     scaleX: 1.1,
    //     scaleY: 1.1,
    //     // transformOrigin:"50% 50%"
    // }, '-=0.5')
    // .to('.doctor-2', 6, {
    //     x: '-=56',
    //     scaleX: 1.1,
    //     scaleY: 1.1,
    //     // transformOrigin:"50% 50%"
    // }, '-=6')
    // .to('.doctor-3, .doctor-3-pill', 6, {
    //     x: '+=20',
    // }, '-=6')
    // .to('.bg-shape', 6, {
    //     x: '+=150',
    // }, '-=6');
    new ScrollMagic.Scene({
            triggerElement: '#trigger',
            triggerHook: 0,
            duration: '250%',
        })
        .setTween(tl)
        .setPin('.hero', {
            pushfollowers: false,
        })
        .addTo(controller);
};

const animateSite = () => {
    const controller = new ScrollMagic.Controller();
    const tl3 = new TimelineMax();
    const tl4 = new TimelineMax();
    const tl6 = new TimelineMax();
    const tl7 = new TimelineMax();
    const tl8 = new TimelineMax();
    const tl9 = new TimelineMax();

    /* * Hero title disappear * */
    const tlHero = new TimelineMax()
        .to('.hero__text .title', 0.5, {
            y: '-10%',
            autoAlpha: 0,
            ease: Power0.easeNone,
        })
        .to('.hero__text p', 0.5, {
            y: '-25%',
            autoAlpha: 0,
            ease: Power0.easeNone,
        }, 0);

    new ScrollMagic.Scene({
            triggerElement: '.can-do',
            triggerHook: 0.99,
            duration: '30%',
        })
        .setTween(tlHero)
        .addTo(controller);

    /* * Section Can do * */

    const tlCanDoImg = new TimelineMax()
        .fromTo('.can-do .can-do__img', 0.5, {
            autoAlpha: 0,
            y: '10%',
            x: '7%',
            ease: Power0.easeNone,
        }, {
            autoAlpha: 1,
            y: '0%',
            x: '7%',
            ease: Power0.easeNone,
        }, 0.1);
    new ScrollMagic.Scene({
            triggerElement: '.can-do',
            triggerHook: 0.5,
            duration: '30%',
        })
        .setTween(tlCanDoImg)
        .addTo(controller);

    const tlCanDoList = new TimelineMax()
        .staggerFromTo('.can-do__list-item', 0.8, {
            autoAlpha: 0,
            y: '30%',
            ease: Power0.easeNone,
        }, {
            autoAlpha: 1,
            y: '0%',
            ease: Power0.easeNone,
        }, 0.1);
    new ScrollMagic.Scene({
            triggerElement: '.can-do',
            triggerHook: 0.2,
            duration: '30%',
        })
        .setTween(tlCanDoList)
        .addTo(controller);

    const tlIngr1 = new TimelineMax({
            repeat: -1,
            repeatDelay: 1
        })
        .to('.bounce-shape', 1.5, {
            bezier: {
                curviness: 0,
                values: [{
                        x: '+=0',
                        y: '-=10',
                    },
                    {
                        x: '+=3',
                        y: '-=15',
                    },
                    {
                        x: '+=5',
                        y: '-=20',
                    },
                    {
                        x: '+=5',
                        y: '-=25',
                    },
                    {
                        x: '+=3',
                        y: '-=30',
                    },
                ],
            },
            scaleX: 1.3,
            scaleY: 1.3,
            ease: Power1.easeIn,
        })
        .to('.bounce-shape', 0.4, {
            autoAlpha: 0,
        });
    const tlIngr2 = new TimelineMax({
            repeat: -1,
            repeatDelay: 1
        })
        .to('.bounce-shape1', 2, {
            bezier: {
                curviness: 0,
                values: [{
                        x: '+=10',
                        y: '-=10',
                    },
                    {
                        x: '+=13',
                        y: '-=13',
                    },
                    {
                        x: '+=15',
                        y: '-=17',
                    },
                    {
                        x: '+=15',
                        y: '-=20',
                    },
                    {
                        x: '+=13',
                        y: '-=25',
                    },
                ],
            },
            scaleX: 0.9,
            scaleY: 0.9,
            ease: Power0.easeNone,
        })
        .to('.bounce-shape1', 0.4, {
            autoAlpha: 0,
        });
    const tlIngr3 = new TimelineMax({
            repeat: -1,
            repeatDelay: 1
        })
        .to('.bounce-shape2', 2, {
            bezier: {
                curviness: 0,
                values: [{
                        x: '-=10',
                        y: '-=20',
                    },
                    {
                        x: '-=13',
                        y: '-=23',
                    },
                    {
                        x: '-=15',
                        y: '-=27',
                    },
                    {
                        x: '-=15',
                        y: '-=30',
                    },
                    {
                        x: '-=13',
                        y: '-=35',
                    },
                ],
            },
            scaleX: 0.9,
            scaleY: 0.9,
            ease: Power0.easeNone,
        })
        .to('.bounce-shape2', 0.4, {
            autoAlpha: 0,
        });
    const tlIngr4 = new TimelineMax({
            repeat: -1,
            repeatDelay: 1
        })
        .to('.bounce-shape3', 3, {
            bezier: {
                curviness: 0,
                values: [{
                        x: '-=10',
                        y: '-=20',
                    },
                    {
                        x: '-=13',
                        y: '-=23',
                    },
                    {
                        x: '-=15',
                        y: '-=27',
                    },
                    {
                        x: '-=15',
                        y: '-=30',
                    },
                    {
                        x: '-=13',
                        y: '-=35',
                    },
                ],
            },
            scaleX: 0.9,
            scaleY: 0.9,
            ease: Power0.easeNone,
        })
        .to('.bounce-shape3', 0.4, {
            autoAlpha: 0,
        });
    const tlIngr5 = new TimelineMax({
            repeat: -1,
            repeatDelay: 1
        })
        .to('.bounce-shape4', 2.5, {
            bezier: {
                curviness: 0,
                values: [{
                        x: '-=10',
                        y: '+=2',
                    },
                    {
                        x: '-=13',
                        y: '+=1',
                    },
                    {
                        x: '-=15',
                        y: '-=0',
                    },
                    {
                        x: '-=18',
                        y: '+=2',
                    },
                    {
                        x: '-=20',
                        y: '+=3',
                    },
                ],
            },
            scaleX: 0.9,
            scaleY: 0.9,
            rotation: 360,
            transformOrigin: 'center center',
            ease: Power0.easeNone,
        })
        .to('.bounce-shape4', 0.4, {
            autoAlpha: 0,
        });
    const tlIngr6 = new TimelineMax({
            repeat: -1,
            repeatDelay: 1
        })
        .to('.bounce-shape5', 3, {
            bezier: {
                curviness: 0,
                values: [{
                        x: '+=10',
                        y: '-=20',
                    },
                    {
                        x: '+=13',
                        y: '-=27',
                    },
                    {
                        x: '+=15',
                        y: '-=32',
                    },
                    {
                        x: '+=17',
                        y: '-=40',
                    },
                    {
                        x: '+=25',
                        y: '-=55',
                    },
                ],
            },
            rotation: 150,
            transformOrigin: "50% 50%",
            scaleX: 0.9,
            scaleY: 0.9,
            ease: Power0.easeNone,
        })
        .to('.bounce-shape5', 0.4, {
            autoAlpha: 0,
        });
    const options = {
        transformOrigin: '50% 50%',
        scaleX: 1.06,
        scaleY: 1.06,
        autoAlpha: 0,
        ease: Power0.easeNone,
    };


    const tlIngrFrame = new TimelineMax({
            repeat: -1,
            repeatDelay: 0,
        })
        .fromTo('.ingr1 .ingr-frame, .ingr1 .ingr-frame1', 0.2, {
            transformOrigin: '50% 50%',
            scaleX: 0.9,
            scaleY: 0.9,
            autoAlpha: 0,
            ease: Power0.easeNone,
        }, {
            autoAlpha: 1,
        })
        .to('.ingr1 .ingr-frame, .ingr1 .ingr-frame1', 1.6, {
            transformOrigin: '50% 50%',
            scaleX: 1.07,
            scaleY: 1.07,
            autoAlpha: 0,
            ease: Power0.easeNone,
        });

    const tlIngrFrame1 = new TimelineMax({
            repeat: -1,
            repeatDelay: 0,
        })
        .fromTo('.ingr2 .ingr-frame, .ingr2 .ingr-frame1', 0.2, {
            transformOrigin: '50% 50%',
            scaleX: 0.9,
            scaleY: 0.9,
            autoAlpha: 0,
            ease: Power0.easeNone,
        }, {
            autoAlpha: 1,
        })
        .to('.ingr2 .ingr-frame, .ingr2 .ingr-frame1', 1.2, {
            transformOrigin: '50% 50%',
            scaleX: 1.07,
            scaleY: 1.07,
            autoAlpha: 0,
            ease: Power0.easeNone,
        });
    const tlIngrFrame3 = new TimelineMax({
            repeat: -1,
            repeatDelay: 0,
        })
        .fromTo('.ingr3 .ingr-frame, .ingr3 .ingr-frame1', 0.2, {
            transformOrigin: '50% 50%',
            scaleX: 0.9,
            scaleY: 0.9,
            autoAlpha: 0,
            ease: Power0.easeNone,
        }, {
            autoAlpha: 1,
        })
        .to('.ingr3 .ingr-frame, .ingr3 .ingr-frame1', 2, {
            transformOrigin: '50% 50%',
            scaleX: 1.07,
            scaleY: 1.07,
            autoAlpha: 0,
            ease: Power0.easeNone,
        });
   
   
    const tlLampGradient = new TimelineMax()
        .staggerTo('.descr__top-img svg stop', 1, {
            cycle: {
                stopColor: ['#fceb50', '#fceb50', '#fceb50', '#fceb50', '#fceb50', '#fceb50'],
            },
        }, 0.1, 0);
    new ScrollMagic.Scene({
            triggerElement: '.descr__top-img',
            triggerHook: 0.3,
            duration: '100%',
        })
        .setTween(tlLampGradient)
        .addTo(controller);


    const tlHowUse = new TimelineMax()
        .staggerFromTo('.how-use__row div', 1, {
            cycle: {
                y: [50, -50],
            },
            // y: 50,
            opacity: 0,
            ease: Power0.easeNone,
        }, {
            y: 0,
            opacity: 1,
            ease: Power0.easeNone,
        }, 0.01);

    new ScrollMagic.Scene({
            triggerElement: '.how-use',
            triggerHook: 0.6,
            duration: '50%',
        })
        .setTween(tlHowUse)
        .addTo(controller);

    const tlInsideItems = new TimelineMax()
        .staggerFromTo('.inside__item', 1, {
            y: 30,
            autoAlpha: 0,
            ease: Power0.easeNone,
        }, {
            y: 0,
            autoAlpha: 1,
            ease: Power0.easeNone,
        }, 0.2);

    new ScrollMagic.Scene({
            triggerElement: '.inside__items',
            triggerHook: 0.7,
            duration: '30%',
        })
        .setTween(tlInsideItems)
        .addTo(controller);


    $('.test__item').each(function () {
        const tl = new TimelineLite();
        const tl1 = new TimelineLite();
        const tl2 = new TimelineLite();
        const el = $(this);
        const title = el.find('.test__item-content .test__title');
        const text = el.find('.test__item-content p');
        // const img = el.find('.test__item-img');
        new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 0.8,
                duration: '40%',
            })
            .setTween(tl.fromTo(title, 1, {
                y: '10%',
                opacity: 0,
                ease: Power0.easeNone,
            }, {
                y: '0%',
                opacity: 1,
                ease: Power0.easeNone,
            }))
            .addTo(controller);
        new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 0.7,
                duration: '40%',
            })
            .setTween(tl1.fromTo(text, 1, {
                y: '20%',
                opacity: 0,
                ease: Power0.easeNone,
            }, {
                y: '0%',
                opacity: 1,
                ease: Power0.easeNone,
            }))
            .addTo(controller);
        // new ScrollMagic.Scene({
        //         triggerElement: this,
        //         triggerHook: 0.7,
        //         duration: '40%',
        //     })
        //     .setTween(tl2.fromTo(img, 1, {
        //         y: '20%',
        //         opacity: 0,
        //         ease: Power0.easeNone,
        //     }, {
        //         y: '0%',
        //         opacity: 1,
        //         ease: Power0.easeNone,
        //     }))
        //     .addTo(controller);
    });

    const tlTestItem = new TimelineMax({
            repeat: -1,
            yoyo: true
            // repeatDelay: 1
        })
        .to('.test-item1', 3, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '+=10',
            y: '-=10',
            rotation: 10,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        })
        .to('.test-item1', 3, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '+=10',
            rotation: -10,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        });
    const tlTestItem1 = new TimelineMax({
            repeat: -1,
            yoyo: true
        })
        .to('.test-item1-1', 3, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '+=10',
            y: '-=10',
            rotation: 360,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        })
        .to('.test-item1-1', 3, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '+=10',
            rotation: 360,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        });

    const tlTestItem2 = new TimelineMax({
            repeat: -1,
            yoyo: true
            // repeatDelay: 1
        })
        .to('.test-item2', 4, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '-=15',
            rotation: 10,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        })
        .to('.test-item2', 4, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '+=15',
            rotation: -10,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        });
    const tlTestItem3 = new TimelineMax({
            repeat: -1,
            yoyo: true
        })
        .to('.test-item2-2', 4, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '-=10',
            rotation: 360,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        })
        .to('.test-item2-2', 4, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '+=10',
            rotation: 360,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        });
    const tlTestItem4 = new TimelineMax({
            repeat: -1,
            yoyo: true
            // repeatDelay: 1
        })
        .to('.test-item3', 3.5, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '-=15',
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        })
        .to('.test-item3', 3.5, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '+=15',
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        });
    const tlTestItem5 = new TimelineMax({
            repeat: -1,
            yoyo: true
        })
        .to('.test-item3-2', 3.5, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '-=10',
            rotation: 360,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        })
        .to('.test-item3-2', 3.5, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '+=10',
            rotation: 360,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        });
    const tlTestCircle = new TimelineMax({
            repeat: -1,
            repeatDelay: 0
            // yoyo: true
        })
        .to('.test-circle', 3.5, {
            rotation: "360",
            transformOrigin: 'center center',
            ease: Power0.easeNone,
        });

    const tlTestBatchBelt = new TimelineMax({
            // repeat: -1,
            // repeatDelay: 0
            // yoyo: true
        })
        .to('.test-batch__belt .first', 7, {
            x: '-100%',
            ease: Power0.easeNone,
        })
        .set('.test-batch__belt .first', {
            autoAlpha: 0,
            ease: Power0.easeNone,
        })
        .to('.test-batch__belt .sec', 14, {
            x: '-100%',
            repeat: -1,
            ease: Power0.easeNone,
        }, '-=7')
        .to('.test-batch__belt .third', 14, {
            x: '-100%',
            repeat: -1,
            ease: Power0.easeNone,
        }, '-=7');
    const tlTestBatchBottle = new TimelineMax({
            repeat: -1,
            repeatDelay: 1,
            // yoyo: true
        })

        .to('.test-batch__bottle--mask', 1, {
            autoAlpha: 1,
            ease: Power0.easeNone,
            // repeat: -1,
        })
        .to('.test-batch__check', 0.5, {
            autoAlpha: 1,
            ease: Power3.easeInOut,
            // repeat: -1,
        }, '+=0.5')
        .to('.test-batch__check', 0.5, {
            autoAlpha: 0,
            ease: Power3.easeInOut,
            // repeat: -1,
        }, '+=0.5')
        .to('.test-batch__bottle--mask', 0.5, {
            x: '-160%',
            ease: Power0.easeNone,
            // repeat: -1,
        });

    function animateTestBscg() {
        let firstBox = $('.test-bscg__left img:first-child');
        let secBox = $('.test-bscg__left img:nth-child(2)');
        let lastBox = $('.test-bscg__left img:last-child');
        let rightBox = $('.test-bscg__right img:first-child');
        let rightBoxMiddle = $('.test-bscg__right img:nth-child(2)');
        let rightBoxLast = $('.test-bscg__right img:last-child');
        const tlTestBscg = new TimelineMax({})

            .to('.test-bscg__box', 0.5, {
                top: '10%',
                ease: Power0.easeNone,
            })
            .to('.test-bscg__box', 0.3, {
                top: '+=15%',
                autoAlpha: 1,
                ease: Power0.easeIn,
                // repeat: -1,
            })
            .set('.test-bscg__line', {
                // top: '+=5%',
                autoAlpha: 1,
                ease: Power0.easeNone,
                // repeat: -1,
            })
            // 54.5-25
            //54.1
            .to('.test-bscg__box', 0.8, {
                // top: '+=23.5%',
                top: '+=29.1%',
                autoAlpha: 1,
                ease: Power4.ease,
                // repeat: -1,
            })
            .set('.test-bscg__line', {
                // top: '+=5%',
                autoAlpha: 0,
                ease: Power0.easeNone,
                // repeat: -1,
            })
            .set('.test-bscg__dump', {
                // top: '+=5%',
                autoAlpha: 1,
                ease: Power0.easeNone,
                // repeat: -1,
            })
            .to('.test-bscg__box', 0.8, {
                top: '-=.5%',
                autoAlpha: 1,
                ease: Power4.ease,
                // repeat: -1,
            })
            .to('.test-bscg__dump', 0.5, {
                // top: '+=5%',
                autoAlpha: 0,
                ease: Power0.easeNone,
                // repeat: -1,
            }, '-=0.6')

            .to('.test-bscg__box', 0.8, {
                top: '+=.5%',
                autoAlpha: 1,
                ease: Power4.ease,
                // repeat: -1,
            })
            .set('.test-bscg__box', {

                autoAlpha: 0,
                ease: Power4.ease,
                // repeat: -1,
            })
            .set(firstBox, {

                autoAlpha: 1,
                // ease: Power4.ease,
                // repeat: -1,
            }, '-=0.2')
            .to(firstBox, 0.8, {
                left: '33%',
                // autoAlpha: 0,
                ease: Power4.ease,
                // repeat: -1,
            })
            .to(secBox, 0.8, {
                left: '66%',
                // autoAlpha: 0,
                ease: Power4.ease,
                // repeat: -1,
            }, '-=0.8')
            .to(lastBox, 0.8, {
                left: '99%',
                // autoAlpha: 0,
                ease: Power4.ease,
                // repeat: -1,
            }, '-=0.8')
            .to(rightBox, 0.8, {
                left: '0%',
                // autoAlpha: 0,
                ease: Power4.ease,
                // repeat: -1,
            }, '-=0.8')
            .to(rightBoxMiddle, 0.8, {
                left: '43%',
                ease: Power4.ease,
                // repeat: -1,
            }, '-=0.8')
            .to(rightBoxLast, 0.8, {
                left: '86%',
                autoAlpha: 0,
                ease: Power4.ease,
                // repeat: -1,
            }, '-=0.8')
            .add(function () {
                $('.test-bscg__left img:last-child').remove();
                $('.test-bscg__left').prepend(`<img src="/images/box.svg" alt="/" style="">`)
                $('.test-bscg__right img:last-child').remove();
                $('.test-bscg__right').prepend(`<img src="/images/box-check.svg" alt="/" style="">`)
            })
    }

    animateTestBscg();
    setInterval(() => {
        TweenMax.delayedCall(1, animateTestBscg);
    }, 5000);

    tl7
        .staggerTo('.footer svg stop', 1, {
            cycle: {
                stopColor: ['#fceb50', '#fceb50', '#fceb50', '#fceb50', '#fceb50', '#fceb50'],
            },
        }, 0.1, 0);
    new ScrollMagic.Scene({
            triggerElement: '.footer',
            triggerHook: 0.5,
            duration: '100%',
        })
        .setTween(tl7)
        .addTo(controller);
    tl8
        .to('.footer__img1', 1, {
            y: 0,
        });
    new ScrollMagic.Scene({
            triggerElement: '.footer',
            triggerHook: 0.3,
            duration: '100%',
        })
        .setTween(tl8)
        .addTo(controller);
    tl9
        .to('.footer__img3', 1, {
            y: 0,
        });
    new ScrollMagic.Scene({
            triggerElement: '.footer__img2',
            triggerHook: 0.5,
            // offset: '50%'
            duration: '100%',
        })
        .setTween(tl9)
        .addTo(controller);

    const tlTestStability = new TimelineMax()
        .to('#theLine', 0.7, {
            attr: {
                y2: 796
            },
            ease: Linear.easeNone
        });
    new ScrollMagic.Scene({
            triggerElement: '.test-stability',
            triggerHook: 0.4,
            duration: '100%',
        })
        .setTween(tlTestStability)
        .addTo(controller);

    $('.test-stability-table').each(function () {
        const tlTestStability2 = new TimelineMax()
            .to(this, 0.2, {
                attr: {
                    fill: '#69CBE2'
                }
            });
        new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 0.6,
                // duration: '50%',
            })
            .setTween(tlTestStability2)
            .addTo(controller);
    })


    $('.test-stability__box').each(function () {
        const boxInside = $(this).find('.box-inside');
        const boxBorder = $(this).find('.box-border');
        const boxLine = $(this).find('.box-line');
        const boxLogo = $(this).find('.box-logo');
        const boxCheckBorder = $(this).find('.box-check-border');
        const boxCheckIcon = $(this).find('.box-check-icon');
        const tlTestStabilityBox = new TimelineMax()
            .fromTo(boxCheckBorder, 0.05, {
                attr: {
                    fill: "#f2eee4",
                    stroke: "#e0d8c3"
                },
            }, {
                attr: {
                    fill: "#69cbe2",
                    stroke: '#456e79',
                },
            }, 0.1)
            .fromTo(boxCheckIcon, 0.1, {
                attr: {
                    fill: "#e0d8c3"
                },
            }, {
                attr: {
                    fill: "#fff"
                },
            }, '-=0.1')
            .fromTo(boxInside, 0.1, {
                attr: {
                    fill: "#f2eee4"
                },
            }, {
                attr: {
                    fill: "#69cbe2"
                },
            }, -0.1)
            .fromTo(boxBorder, 0.2, {
                attr: {
                    fill: "#e0d8c3"
                },
            }, {
                attr: {
                    fill: "#456e79"
                },
            }, -0.1)
            .fromTo(boxLine, 0.1, {
                attr: {
                    fill: "rgb(224, 216, 195)"
                },
            }, {
                attr: {
                    fill: "#456e79"
                },
            }, -0.1)
            .fromTo(boxLogo, 0.1, {
                attr: {
                    fill: "rgb(224, 216, 195)"
                },
            }, {
                attr: {
                    fill: "rgb(69, 110, 121)"
                },
            }, -0.1);;
        new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 0.6,
                // duration: '50%',
            })
            .setTween(tlTestStabilityBox)
            .addTo(controller);
    })



}

// const tlSmoke = new TimelineMax({
//         // repeat: -1,
//     })

//     .set('.cls-2', {
        
//         y: '+=84.936',
//         x: '-=90.286',
//         autoAlpha: 0.1,
//         scaleX: 0,
//         scaleY: 0,
// transformOrigin: 'center center'
//     //     //  yoyo: true
//     })
//     .fromTo('#Ellipse_6077', 3,{
//         y: '-=84.936',
//         x: '+=90.286',
//         autoAlpha: 1,
//         scaleX: 1.3,
//         scaleY: 1.3,
//     },{
//         y: '-=84.936',
//         x: '+=90.286',
//         autoAlpha: 0,
//         scaleX: 1.8,
//         scaleY: 1.8,
//     })


const tlTablet1 = new TimelineMax({
        // repeat: -1,
        // yoyo: true
    })

    .to('#Group_7273-42', 3, {
        y: '+=30',
        x: '-=5',
        rotation: 360,
        transformOrigin: 'center center',
        autoAlpha: 0.1,
        repeat: -1,
        //  yoyo: true
    })
const tlTablet2 = new TimelineMax({})

    .fromTo('#Group_7273-43', 2.5, {
        y: '-=5',
        x: '+=0',
        // rotation: 360,
        transformOrigin: 'center center',
        autoAlpha: 1,
        repeat: -1,
        //  yoyo: true
    }, {
        y: '+=30',
        x: '-=5',
        rotation: 360,
        transformOrigin: 'center center',
        autoAlpha: 0.1,
        repeat: -1,
        //  yoyo: true
    })
const tlTablet3 = new TimelineMax({})

    .fromTo('#Group_7273-44', 3, {
        y: '-=14',
        x: '+=5',
        // rotation: 360,
        transformOrigin: 'center center',
        autoAlpha: 1,
        repeat: -1,
        //  yoyo: true
    }, {
        y: '+=30',
        x: '-=2',
        rotation: -300,
        transformOrigin: 'center center',
        autoAlpha: 0.1,
        repeat: -1,
        //  yoyo: true
    }, 0.5)
const tlTablet4 = new TimelineMax({})

    .fromTo('#Group_7273-45', 4, {
        y: '-=20',
        x: '+=5',
        // rotation: 360,
        transformOrigin: 'center center',
        autoAlpha: 1,
        repeat: -1,
        //  yoyo: true
    }, {
        y: '+=35',
        x: '-=5',
        rotation: 300,
        transformOrigin: 'center center',
        autoAlpha: 0.1,
        repeat: -1,
        //  yoyo: true
    }, 0.3)


animateCard();
reviewSlider();
animateGreetScreen();
animateSite();